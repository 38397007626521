$accenture-font: "Graphik", Arial, Helvetica, sans-serif
$accenture-font-medium: "Graphik-Medium", Arial, Helvetica, sans-serif
$accenture-font-semibold: "Graphik-Semibold", Arial, Helvetica, sans-serif

/* Color Palette */
$twilight: #4e4e87
$light-navy: #0f5284
$state-button-primary-01-default: #3f72d2
$turquoise-blue: #06cac6
$greyish: #b4b4b4
$avocado-green: #77a523
$dark-aquamarine: #007970
$warm-grey: #9a9a9a
$silver: #dfe3e6
$white: #ffffff
$black: #333333
$greyish-brown: #555555
$brownish-grey: #777777
$brownish-grey-two: #686868
$brownish-grey-three: #757575
$avocado-10: rgba(127, 172, 44, 0.1)
$dark-grass-green: #4a7b04
$greyish-brown-70: rgba(60, 60, 60, 0.7)
$battleship-grey-60: rgba(109, 113, 115, 0.6)
$twilight-20: rgba(78, 78, 135, 0.2)
$pale-grey: #f2f2f7
$tsm-pale-grey: #eaeffa
$cornflower-blue: #3f72d2
$rouge: #c01a2e
$medium-purple: #774ba2
$dark-grass-green: #4a7b04
$rich-purple: #64005f
$cranberry: #ad002e
$state-button-primary-02-hover: #2e57b3
$state-button-primary-03-hover: #1f4097
$state-button-primary-04-disabled: #cccccc
$state-button-secondary-01-enabled: #f4f4fc
$state-button-secondary-02-hover: #e9e9fa
$state-button-secondary-03-pressed: #d9d9f2
$pale-grey-three: #f0f0ff
$state-button-contained-primary-b-hov: #3660b0
$state-button-contained-secondary-b-h: #e9e6f4
$state-button-contained-primary-c-pre: #27447a
$lipstick: #cc1e32
$state-button-contained-secondary-a-e: #f7f6fa
$state-button-contained-secondary-c-p: #c7c0e4