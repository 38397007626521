@font-face
    font-family: 'Material Icons'
    font-style: normal
    font-weight: 400
    src: url(../fonts/material/MaterialIcons-Regular.eot)
    src: local('Material Icons')
    src: local('MaterialIcons-Regular')
    src: url(../fonts/material/MaterialIcons-Regular-new.woff2) format('woff2')
    src: url(../fonts/material/MaterialIcons-Regular.woff) format('woff')
    src: url(../fonts/material/MaterialIcons-Regular.ttf) format('truetype')

.main_container .collapse 
  margin-top: 1rem
  font-size: 16px
  font-weight: 600
  border-bottom: #ccc 1px solid
  font-family: 'Graphik-Medium'
  // color: #666
  color: #4e4e87
  padding-bottom: 0.5rem
  display: block

.main_container .title 
    font-size: 20px
    font-weight: 700
    // color: #666
    color: #4e4e87
    font-family: 'Graphik-Medium'

.material-icons 
    font-family: 'Material Icons'
    font-weight: normal
    font-style: normal
    font-size: 24px 
    display: inline-block
    line-height: 1
    text-transform: none
    letter-spacing: normal
    word-wrap: normal
    white-space: nowrap
    direction: ltr
    vertical-align: middle
  
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility
  
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale
  
    /* Support for IE. */
    font-feature-settings: 'liga'

.main_container 
    background: #fff
    border-top-left-radius: 2rem
    border-bottom-right-radius: 2rem
    padding: 2.1rem
    position: relative


.main_container .tabs  
    position: absolute
    right: 2.1rem
    top: 2rem 

.main_container .tabs label 
    margin-left: 2rem
    color: #4e4e87
    font-weight: bold
    padding-bottom: 6px

.main_container .tabs label.active 
    border-bottom: #4e4e87 2px solid


.main_container .rc-container 
    display: inline !important
    position: relative
    padding-left: 26px
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    font-weight: normal
    color: #333
    margin-right: 3rem
    padding-top: .3rem

.main_container .custom-form-group label 
    display: block
    margin-bottom: 0.5rem
    font-weight: bold
    color: #555
    // color: #333
    line-height: 1.25rem
    font-size: 14px
    font-family: 'Graphik'
    margin-top: 1rem

.main_container .checkmark 
    position: absolute
    top: 6px
    left: 0
    background-color: #fff
    content: ""
    width: 17px
    height: 17px
    border: 1.4px solid #4e4e87
    border-radius: 3px
    display: inline-block
    vertical-align: middle

.checkmark:after 
    content: ""
    position: absolute
    display: none

.main_container .custom-form-group 
    padding: 10px


.main_container .checkmark.r 
    border-radius: 50%

.main_container .rc-container:hover input~.checkmark 
    background-color: #fff

.main_container .custom-form-group label .normalWeight 
    // font-weight: normal
    font-weight: 600
    font-size: 14px
    line-height: 20px
    color: #555
    font-style: normal
    font-family: 'Graphik'
    font-stretch: normal
    letter-spacing: normal

.main_container .rc-container input 
    position: absolute
    opacity: 0
    cursor: pointer
    height: 0
    width: 0

.main_container .rc-container input:checked~.checkmark 
    background-color: #4e4e87


.main_container .rc-container input:checked~.checkmark:after 
    display: block


.main_container .rc-container .checkmark:after 
    left: 4px
    top: 1px
    width: 6px
    height: 10px
    border: solid white
    border-width: 0 .15rem .15rem 0
    -webkit-transform: rotate(45deg)
    -ms-transform: rotate(45deg)
    transform: rotate(45deg)



select 
    // background: rgba(78, 78, 135, 0.1) url("../images/baseline-expand_more.svg") no-repeat right .75rem center !important
    background: #f6f6f9 url("../images/baseline-expand_more.svg") no-repeat right .75rem center !important
    background-size: 1.5rem !important
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    padding: 0.375rem 2rem 0.375rem 0.75rem !important


select:focus 
    background: #ffffff url("../images/baseline-expand_more.svg") no-repeat right .75rem center !important


.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb 
    background-color: #fff!important
    box-shadow: 0 0 0 3px #ccc


.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar 
    background-color: #2E8548!important


.mat-slide-toggle .mat-slide-toggle-bar 
    background-color: #ff6369!important


.mat-slide-toggle.mat-disabled 
    opacity: 100!important


.material-icons.md-18 
    font-size: 18px 
.material-icons.md-24 
    font-size: 24px 
.material-icons.md-36 
    font-size: 36px 
.material-icons.md-48 
    font-size: 48px 
  

.material-icons.md-dark 
    color: rgba(0, 0, 0, 0.54) 
.material-icons.md-dark.md-inactive 
    color: rgba(0, 0, 0, 0.26) 
  

.material-icons.md-light 
    color: rgba(255, 255, 255, 1) 
.material-icons.md-light.md-inactive 
    color: rgba(255, 255, 255, 0.3) 


.main_container .custom-form-group span.customSubLabel 
    display: block
    font-style: italic
    font-weight: 100
    font-size: 14px
    font-family: 'Graphik'

app-dialog button
    margin: 0
    color: black !important

.form_validation_message 
    // background: #FFE8E6
    // color: #633752
    color: red
    // padding: .75rem
    border-radius: .25rem
    font-size: 0.9rem

 
.form_validation_message1 
    background: rgb(250, 225, 186, .5)
    color: #633752
    padding: .75rem
    border-radius: .25rem
    font-size: 0.9rem

 
.form_validation_message .material-icons
    font-size: 1.25rem
    color: #CC1E32
    margin-right: .5rem
    display: none
 
.waringMsg 
    color: #6E4E03 !important

 
.form_validation_message1 .material-icons 
    font-size: 1.25rem
    color: #6E4E03
    margin-right: .5rem

 
.form_validation_message .message 
    flex-grow: 1

.form-control
    height: 36px
    border-radius: 8px
    // background: rgba(78, 78, 135, 0.1)
    background: #f6f6f9
    border: 1px solid #ced4da !important

.form-control:focus
    box-shadow: none

.form-control input.multi-drop-input
    background: rgba(78, 78, 135, 0)

.main_container .collapse.sub_title
    color: rgb(78, 78, 135)
    font-family: Graphik
    font-weight: 600

.shortDescDiv .form-control
    min-height: 5rem

.people-div
    border: none !important

.mat-datepicker-input
    background: #f6f6f9 !important