$accenture-font: "Graphik", Arial, Helvetica, sans-serif
$accenture-font-medium: "Graphik-Medium", Arial, Helvetica, sans-serif
$accenture-font-semibold: "Graphik-Semibold", Arial, Helvetica, sans-serif

.page
    margin: 2.5rem
.page-content
    border-radius: 10px
    background-color: #fff
    padding: 2rem

    box-shadow: 0 0 3px 3px rgba(204,204,204,.4)
.hover_pointer 
    cursor: pointer

.width-5
    min-width: 5%

.width-10
    min-width: 10%

.width-15
    min-width: 15%

.width-20
    min-width: 20%

.width-25
    min-width: 25%

.width-30
    min-width: 30%
