/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@use 'variables'
@use 'mixins'

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap'
@import "./assets/styles/common.sass"
@import "./assets/styles/riskassessment"
@import "../node_modules/bootstrap/dist/css/bootstrap-grid.min.css"
@import "~@angular/material/prebuilt-themes/indigo-pink.css"

@font-face
    font-family: "Graphik"
    src: local("Graphik"), url("./assets/fonts/Graphik-Regular.ttf") format("truetype")

@font-face
    font-family: "Graphik-Medium"
    src: local("Graphik-Medium"), url("./assets/fonts/Graphik-Medium.ttf") format("truetype")

@font-face
    font-family: "Graphik-Semibold"
    src: local("Graphik-Semibold"), url("./assets/fonts/Graphik-Semibold.ttf") format("truetype")

@font-face
    font-family: "Graphik-Regular Italic"
    src: local("Graphik-Regular Italic"), url("./assets/fonts/Graphik-Regular Italic.ttf") format("truetype")

html,
body
    height: 100%
    position: relative

tsm-root
    min-height: 100%
    display: block
    position: relative
    padding-bottom: 56px
    background-color: #f2f2f2
    overflow: hidden

/* Modal Component Custom Class (must be injected into body rather than component) */
.modal-container
    .modal-dialog .modal-content
        width: 46.88vw
        max-height: 69.43vh
        border-radius: 16px
        box-shadow: 0 2px 8px 0 rgba(109, 113, 115, 0.5)
        background-color: #ffffff
        font-stretch: normal
        font-style: normal
        line-height: normal
        letter-spacing: normal
        font-family: variables.$accenture-font
    .modal-dialog-centered
        justify-content: center

/* Modal Component Backdrop Custom Class (must be injected into body rather than component) */
.modal-custom-backdrop
    background-blend-mode: multiply
    background-image: linear-gradient(to bottom, rgba(51, 51, 51, 0.3), rgba(51, 51, 51, 0.3))

/* Custom styling for the add supplier parent modal */
.add-parent-modal-window .modal-dialog
  max-width: 710px

/* Custom styling for the supplier update SA modal */
.update-sa-modal-window .modal-dialog
  max-width: 710px

.update-sa-modal-window .modal-content
  border-radius: 1rem

/* Custom styling for the message pop up modal */
.message-pop-up-modal-window .modal-dialog
  max-width: 710px

.message-pop-up-modal-window .modal-content
  border-radius: 1rem

  

/* Custom scroll bar */
::-webkit-scrollbar
    width: 16px

::-webkit-scrollbar-thumb
    background: #5b63e9
    border-radius: 10px

::-webkit-scrollbar-thumb:hover
    background: variables.$twilight

/* Popup Component Custom Class (must be injected into body rather than component) */
.countdown-termination .modal-content
    position: relative
    display: flex
    flex-direction: column
    width: 100%
    pointer-events: auto
    background-color: #fff
    background-clip: padding-box
    border: 1px solid rgba(0, 0, 0, 0.2)
    border-radius: 20px
    outline: 0

.modal-reject .modal-content
    width: 100%
    height: 282px
    padding: 0 0 199px
    border-radius: 16px
    box-shadow: 0 2px 8px 0 rgba(109, 113, 115, 0.5)
    position: relative
    display: flex
    flex-direction: column
    pointer-events: auto
    background-color: #fff
    background-clip: padding-box
    border: 1px solid rgba(0, 0, 0, 0.2)
    outline: 0

.modal-terminate .modal-content
    width: 100%
    height: 282px
    padding: 0 0 199px
    border-radius: 16px
    box-shadow: 0 2px 8px 0 rgba(109, 113, 115, 0.5)
    position: relative
    display: flex
    flex-direction: column
    pointer-events: auto
    background-color: #fff
    background-clip: padding-box
    border: 1px solid rgba(0, 0, 0, 0.2)
    outline: 0

.modal-onboardingReq .modal-content
    position: relative
    display: flex
    flex-direction: column
    width: 100%
    
    pointer-events: auto
    background-color: #fff
    background-clip: padding-box
    border: 1px solid rgba(0, 0, 0, 0.2)
    border-radius: 16px
    outline: 0
    box-shadow: 0 2px 8px 0 rgba(109, 113, 115, 0.5)
    

.modal-possible-scenarios .modal-content
    width: 675px
    height: 1160px
    padding: 0 0 199px
    border-radius: 16px
    box-shadow: 0 2px 8px 0 rgba(109, 113, 115, 0.5)
    position: relative
    display: flex
    flex-direction: column
    pointer-events: auto
    background-color: #fff
    background-clip: padding-box
    border: 1px solid rgba(0, 0, 0, 0.2)
    outline: 0
    left: 50%
    transform: translateX(-50%)
    padding-bottom: 0px
    overflow: auto

.modal-onboarding .modal-content
    width: auto
    height: auto
    padding: 0 0 199px
    border-radius: 16px
    box-shadow: 0 2px 8px 0 rgba(109, 113, 115, 0.5)
    position: relative
    display: flex
    flex-direction: column
    pointer-events: auto
    background-color: #fff
    background-clip: padding-box
    border: 1px solid rgba(0, 0, 0, 0.2)
    outline: 0
    left: 50%
    transform: translateX(-50%)
    padding-bottom: 0px

.countdown-termination .modal-content
    width: 683px
    margin: auto
.countdown-modal .modal-content
    width: 44.88vw
    max-height: 69.43vh
    border-radius: 16px
    box-shadow: 0 2px 8px 0 rgba(109, 113, 115, 0.5)
    background-color: #ffffff
    font-stretch: normal
    font-style: normal
    line-height: normal
    letter-spacing: normal
    font-family: variables.$accenture-font
    
.modal-faq .modal-content
    width: 675px
    padding: 0 0 199px
    border-radius: 16px
    box-shadow: 0 2px 8px 0 rgba(109, 113, 115, 0.5)
    position: relative
    display: flex
    flex-direction: column
    pointer-events: auto
    background-color: #fff
    background-clip: padding-box
    border: 1px solid rgba(0, 0, 0, 0.2)
    outline: 0
    left: 50%
    transform: translateX(-50%)
    padding-bottom: 0px
.faqBlue 
    background: #3f72d2 !important
.faqGray
    background: gray !important

.number-text
  color: #FFFFFF !important

/* Ngb Pagination Bar Selected Item */
.page-item.active .page-link
    background-color: variables.$twilight
    color: variables.$white
    font-family: variables.$accenture-font
    font-size: 14px

/* Ngb Pagination */
.page-item
    .page-link
        background-color: variables.$white
        color: variables.$twilight
        font-family: variables.$accenture-font
        font-size: 14px
        padding-left: 1.12vw
        padding-right: 1.12vw

    a[aria-label="First"]
        padding-left: 1.45vw
        padding-right: 1.45vw
        span
            display: none
        &::before
            content: "<<"

    a[aria-label="Previous"]
        span
            display: none
        &::before
            content: "<"

    a[aria-label="Next"]
        span
            display: none
        &::before
            content: ">"

    a[aria-label="Last"]
        padding-left: 1.45vw
        padding-right: 1.45vw
        span
            display: none
        &::before
            content: ">>"
.sk-chase
  width: 60px
  height: 60px
  position: relative
  margin: auto auto
  animation: sk-chase 2.5s infinite linear both

.sk-chase-dot
  width: 100%
  height: 100%
  position: absolute
  left: 0
  top: 0
  animation: sk-chase-dot 2.0s infinite ease-in-out both

.sk-chase-dot:before
  content: ''
  display: block
  width: 25%
  height: 25%
  background-color: #4e4e87
  border-radius: 100%
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both

.sk-chase-dot:nth-child(1)
  animation-delay: -1.1s
.sk-chase-dot:nth-child(2)
  animation-delay: -1.0s
.sk-chase-dot:nth-child(3)
  animation-delay: -0.9s
.sk-chase-dot:nth-child(4)
  animation-delay: -0.8s
.sk-chase-dot:nth-child(5)
  animation-delay: -0.7s
.sk-chase-dot:nth-child(6)
  animation-delay: -0.6s
.sk-chase-dot:nth-child(1):before
  animation-delay: -1.1s
.sk-chase-dot:nth-child(2):before
  animation-delay: -1.0s
.sk-chase-dot:nth-child(3):before
  animation-delay: -0.9s
.sk-chase-dot:nth-child(4):before
  animation-delay: -0.8s
.sk-chase-dot:nth-child(5):before
  animation-delay: -0.7s
.sk-chase-dot:nth-child(6):before
  animation-delay: -0.6s

@keyframes sk-chase
  100%
    transform: rotate(360deg)

@keyframes sk-chase-dot
  80%, 100%
    transform: rotate(360deg)

@keyframes sk-chase-dot-before
  50%
    transform: scale(0.4)
  100%, 0%
    transform: scale(1.0)

.a-underline
  text-decoration: underline
.col-form-label-human-html
    display: flex !important
.col-form-label-human-content
    margin-left: 0.5%

.link
  font-size: 16px
  color: #3f72d2
  font-family: "Graphik-Regular", Arial, Helvetica, sans-serif
  text-decoration: underline
  &:focus
    border: 1px solid #ffffff

.privacyLink
  color: #0056b2
  text-decoration: underline
  &:focus
    border: 1px solid #ffffff

.policyLink
  color: #0056b2
  text-decoration: underline
  &:focus
    border: 1px solid #ffffff

.settingsLink
  color: #0056b2
  text-decoration: underline
  &:focus
    border: 1px solid #ffffff

.informationLink
  color: #0056b2
  text-decoration: underline
  &:focus
    border: 1px solid #ffffff
iframe
    border: none

.emailTextColor
  color: #08449b